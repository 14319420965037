<template>
  <div class="">
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot pb-2">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-2">{{ this.getSiteProps('general.admin-ncsa-member-report-title') || 'Recruiting Player Registration Reports' }}</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-4" id="member_reg_report_table">
                    <div class="data-tablebutn pb-3">
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                        <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                        <i class="fas fa-redo"></i> Reset
                      </b-button>
                      <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                      </b-button>
                    </div>
                    <div class="search-box">
                      <input type="text" v-model="searchValue" class="input-field form-control" style="width:100%; max-width:234px" name="general_search" id="general_search" @keyup="queryForKeywords">
                      <i class="fas fa-search" style="position:absolute"></i>
                    </div>
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="all">Member Id</th>
                        <th class="all">Athlete First Name</th>
                        <th class="all">Athlete Last Name</th>
                        <th class="all">Team Id</th>
                        <th class="all">Team Name</th>
                        <th class="none">Date of Birth</th>
                        <th class="none">Graduation Year</th>
                        <th class="none">Zip Code</th>
                        <th class="none">State</th>
                        <th class="all">Parent First Name</th>
                        <th class="all">Parent Last Name</th>
                        <th>Parent Email</th>
                        <th>Parent Phone Number</th>
                        <th>Age Division</th>
                        <th>Recruiting</th>
                        <th class="none">League</th>
                        <th class="none">Date Paid</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- Data will be placed here -->
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                    <form ref="form" method="post" @submit.prevent="advancedSearch()">
                      <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Member Id</label>
                              <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Email</label>
                              <input type="text" id="email" class="form-control" v-model="searchForm.email">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">First Name</label>
                              <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Last Name</label>
                              <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Effective From</label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <date-pick
                                      v-model="searchForm.registration_start_date"
                                      :format="'MM/DD/YYYY'"
                                      :displayFormat="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                </div>
                              </div>
                              <input id="registration_start_date" type="hidden" class="form-control" :value="registration_start_date_1">
                              <div class="text-sm text-danger">{{ errors.registration_start_date }}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Effective To</label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <date-pick
                                      v-model="searchForm.registration_end_date"
                                      :format="'MM/DD/YYYY'"
                                      :displayFormat="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                </div>
                              </div>
                              <input id="registration_end_date" type="hidden" class="form-control" :value="registration_end_date_1">
                              <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="this.getSiteProps('memberRegistration.team.fields.club_info.age_division')" v-show="fieldsShow">
                            <div class="form-group">
                              <label class="form-label">Age Division</label>
                              <select class="form-control" id="age_division" v-model="searchForm.age_division">
                                <option value="">Select Age Division</option>
                                <option v-for="(discipline, id) in sportDisciplinesSelect2" :value="discipline.id" :key="id">{{ discipline.text }}</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6" v-show="fieldsShow">
                            <div class="form-group">
                              <label for="team_id" class="form-label">Team</label>
                              <select class="form-control" id="team_id" v-model="searchForm.team_id">
                                <option value="">Select Team</option>
                                <option v-for="(TeamData,key) in teams" :key="key" :value="TeamData.id">
                                  {{ TeamData.text }}
                                </option>
                              </select>
                              <span class="text-sm text-danger"></span>
                            </div>
                          </div>
                          <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Recruiting</label>
                              <select id="member_ncsa" class="form-control" v-model="searchForm.member_ncsa">
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                        <div class="col-md-6" v-show="fieldsShow" v-if="subdomain == 'alliancefastpitch'">
                          <div class="form-group">
                            <label class="form-label">Recruiting Team Membership Benefits</label>
                            <select class="form-control" v-model="searchForm.member_ncsa" id="membership_benefits">
                              <option value="">Select</option>
                              <option v-for="(leagueData,key) in teamMembershipBenefits" :key="key" :value="leagueData.text">
                                {{ leagueData.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-show="fieldsShow" v-if="subdomain == 'alliancefastpitch'">
                          <div class="form-group">
                            <label class="form-label">Graduation Year</label>
                            <select class="form-control" v-model="searchForm.graduation_year" id="graduation_year">
                              <option value="">Select Graduation Year</option>
                              <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 modal-searchbtn text-center">
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                        </div>
                        <div class="text-sm text-danger">{{ errors.not_found }}</div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->
    <Footer></Footer>
  </div>
</template>

<script>
// import DownloadExcel from "vue-json-excel";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from 'moment';
import $ from "jquery";
import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
  if (name.length || name != "") {
    return { valid: true, error: null };
  }
  return { valid: false, error: null };
};

let cachedData = {};
export default {
  components: {  },
  data() {
    return {
      searchValue:'',
      exportProgress: false,
      filtersApplied: false,
      loaded: false,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key :'team_name',
          label :'Team Name',
          sortable: true
        },
        {
          key :'type',
          label :'Type',
          sortable: true
        },
        {
          key :'first_name',
          label :'First Name',
          sortable: true
        },
        {
          key :'last_name',
          label :'Last Name',
          sortable: true
        },
        {
          key :'updated_at',
          label :'Registered',
          sortable: true
        },
        {
          key: 'status',
          label :'Status',
          sortable: true,
        },
        {
          key: 'transaction_id',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 20, text: '50' },
        { value: 20, text: '100' },
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      searchForm: {
        confirmation_code:'',
        first_name: '',
        last_name: '',
        email: '',
        member_ncsa:'',
        graduation_year:'',
        registration_start_date: '',
        registration_end_date: '',
      },
      button: {
        SearchButton: 'Search'
      },
      errors: {},
      table: {},
      clubTypes: [],
      membershipData: [],
      teamMembershipBenefits:[
        {"id": "1", "text": "Yes"},
        {"id": "0", "text": "No"}
      ],
      disciplines:[],
      disciplines_list:[],
      ageDivisionOPt: [
        {"id": "4", "text": "12U"},
        {"id": "5", "text": "10U"}
      ],
      membershipType: [
        {"id": "1", "text": "Individual"},
        {"id": "4", "text": "Club"}
      ],
      yearArr:[],
      fieldsShow:false,
    }
  },
  methods: {
    queryForKeywords(event) {
      const value = event.target.value
      this.keywords = value
      if(!isNaN(value) && value != '' && value.length == 9 ){
        this.searchForm.confirmation_code = value;
        console.log('if',value);
        this.advancedSearch();
      }else{
        this.searchForm.confirmation_code = '';
        console.log('else',value);
        this.table.search(value).draw();
      }
    },
    formatLongDate(value1) {
      var date = moment(value1);
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    formatDate(value)
    {
      var date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if(date.isValid()){
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    viewRow(item, index, button){
      this.infoModal.title = item.first_name + ' ' + item.last_name;
      this.infoModal.content = item;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    editRow(id){
      console.log('Editing row...' + id);
    },
    deleteRow(id){
      console.log('Deleting row...' + id);
    },
    loadData: function () {
      axios.post(process.env.VUE_APP_URL + 'api/reports/registration/Individual', this.searchForm)
          .then((response) => {
            cachedData = response.data.registrations;
            this.items = cachedData;
            this.loaded = true;
            this.button.SearchButton = 'Search';
            this.items = cachedData;
            this.loaded = true;
            if(this.loaded == true) {
              this.$refs['modal-member-report'].hide();
            }
            return true;
          })
          .catch(error => this.errors = error.response.data);
    },
    advancedSearch() {
      if(!this.validate()) {
        return;
      }
      this.button.SearchButton = 'Searching...';
      // Prevent modal from closing
      //bvModalEvt.preventDefault()
      this.loaded = false;
      // Trigger submit handler
      this.table.draw();
      this.filtersApplied = true;
      this.$refs['modal-member-report'].hide();
      this.button.SearchButton = 'Search';
    },
    // Custom Validation Rules
    validate() {
      this.errors = {}
      this.valid =  true;
      const validStartDate = validateName(this.searchForm.registration_start_date);
      this.errors.registration_start_date = validStartDate.error;

      const validEndDate = validateName(this.searchForm.registration_end_date);
      this.errors.registration_end_date = validEndDate.error;

      var dateValid = false;
      if(validStartDate.valid && !this.validateDate(this.searchForm.registration_start_date)){
        dateValid = true;
        this.errors.registration_start_date = "Invalid Date";
      }
      if(validEndDate.valid && !this.validateDate(this.searchForm.registration_end_date)){
        dateValid = true;
        this.errors.registration_end_date = "Invalid Date";
      }

      if(dateValid == false){
        if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.registration_end_date).isBefore(this.searchForm.registration_start_date))) {
          this.errors.registration_end_date = 'End date must be greater than Start date!';
          return this.valid =  false;
        } else {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }

      return this.valid;
    },
    refreshList(event){
      //this.table.clear().draw();
      this.searchForm = {
        member_ncsa:'',
        graduation_year:'',
        confirmation_code:'',
        first_name: '',
        last_name: '',
        email: '',
        registration_start_date: '',
        registration_end_date: '',
        transaction_start_date: '',
        transaction_end_date: '',
        registration_dob:'',
        gender: '',
        year: '',
        membership_id: '',
        status: '',
        transaction_id: '',
        region_name:'',
        groups:[],
        tags:[],
        volunteering_roles:'',
        league:'',
        age_division:'',
        season:''
      };
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    exportCsv(){
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_URL + "api/reports/export/ncsa-registrations/playerNCSA",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function(){
          self.exportProgress = true;
        },
        "success": function(res, status, xhr) {
          self.exportProgress = false;
          if(res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function(err){
          console.log(err);
          self.exportProgress = false;
        }
      });
    },
    Search(event) {
      this.term = this.$route.query.term;
      let email = '';
      let member_id = '';
      let name = '';
      if(this.term !== undefined) {
        if (this.term.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
          email = this.term;
        } else if(this.term.match(/^[0-9]+$/) && this.term != '') {
          member_id = this.term;
        } else {
          name = this.term;
        }
        this.searchForm.year = "";
        this.searchForm.confirmation_code = member_id;
        this.searchForm.first_name = name;
        this.searchForm.last_name = name;
        this.searchForm.email = email;
        this.advancedSearch();
      }
    },
  },
  mounted(){
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
    var self = this;
    var type = self.searchForm.membership_type
    this.table = $("#list_table").DataTable({
      sDom: "lfrtip",
      processing: true,
      serverSide: true,
      pageLength: 10,
      responsive: true,
      "bFilter": true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/reports/ncsa/player",
        type: 'post',
        data: function (d) {
          d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
          d.first_name = $('#first_name').val() || self.searchForm.first_name;
          d.last_name = $('#last_name').val() || self.searchForm.last_name;
          d.email = $('#email').val() || self.searchForm.email;
          d.member_ncsa = $('#member_ncsa').val() || self.searchForm.member_ncsa;
          d.start_date = $('#registration_start_date').val() || self.searchForm.registration_start_date;
          d.end_date = $('#registration_end_date').val() || self.searchForm.registration_end_date;
        }
      },
      columns: [
        { data: 'confirmation_code', name: 'confirmation_code', render: (data)=>{return data || "N/A";}},
        { data: "first_name", name: "personal_details.first_name", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "last_name", name: "personal_details.last_name", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "team_id", name: "activeTeam.members.confirmation_code", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "team_name", name: "activeTeam.name", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "dob", name: "personal_details.dob", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch"},
        { data: "graduation_year", name: "personal_details.graduation_year", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "zip_code", name: "contact_details.zip", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "stateCode", name: "contact_details.state_code", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "parent_first_name", name: "contact_details.first_name", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "parent_last_name", name: "contact_details.last_name", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "email", name: "contact_details.email", render: (data)=>{return '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || "N/A";}},
        { data: "phone", name: "contact_details.phone_1", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "age_division", name: "activeTeam.age_division", render: (data)=>{return data || "N/A"}, searchable: false },
        { data: "membership_benefits", name: "personal_details.membership_benefits", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "member_league", name: "activeTeam.member_league", render: (data)=>{return data || "N/A"}, searchable: false },
        { data: "transaction_date", name: "transaction_date", render: (data)=>{return this.formatLongDate(data)} },


      ],
      columnDefs: [
        { targets: [0,3], orderable: false, visible: this.subdomain=='alliancefastpitch' },
      ],
      order: [[1, "asc"]]
    });
    const year = new Date().getFullYear();
    const yrs = year+20;
    const twoDigitYear = yrs.toString().substr(-2);
    const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
    this.yearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
    this.Search();

  },

  computed: {
    rows() {
      return this.items.length
    },
    years () {
      return new Date().getFullYear() + 1;
    },
    registration_start_date_1: function() {
      return this.searchForm.registration_start_date;
    },
    registration_end_date_1: function() {
      return this.searchForm.registration_end_date;
    }
  }
}

</script>
